import React, { FC } from "react"

interface ButtonProps {
  text: string
  active?: boolean
  small?: boolean
  padded?: boolean
  disabled?: boolean
  onClick?: () => void
}

const Button: FC<ButtonProps> = ({
  text,
  active,
  small,
  onClick,
  disabled,
  padded = true,
}) => {
  const sharedClasses =
    "transition-colors duration-200 ease-in-out inline-block w-auto px-4 py-1 font-bold text-base uppercase rounded-md hover:shadow focus:outline-none"
  const activeClasses = "bg-main-lightsmoke text-main-blue shadow-md "
  const normalClasses = "bg-main-blue text-main-smoke "
  const fontSizeClasses = small ? "" : " md:text-lg lg:text-xl"
  const paddingClasses = padded ? " mt-2 mb-4 " : ""
  const disabledClasses = disabled
    ? "opacity-25 cursor-auto"
    : " hover:bg-main-smoke hover:text-main-blue cursor-pointer"

  return (
    <button
      disabled={disabled}
      className={
        (active ? activeClasses : normalClasses) +
        sharedClasses +
        fontSizeClasses +
        paddingClasses +
        disabledClasses
      }
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default Button
