import { Link } from "gatsby"
import React, { FC } from "react"
import Button from "./button"
import { MenuOption } from "./navigationbar"
import OrangeBanner from "./orange-banner"

interface LeftOverlayProps {
  title: string
  button?: string
  onClick?: () => void
  link?: MenuOption
}

const LeftOverlay: FC<LeftOverlayProps> = (props) => {
  return (
    <div className="relative">
      <div className="absolute flex items-end w-full" style={{ height: "80%" }}>
        <div className="absolute z-10 hidden pr-8 overflow-hidden leading-tight tracking-tighter bg-white bg-opacity-75 md:block">
          <div className="pl-24">
            <h1 className="mt-4 text-center text-main-blue">{props.title}</h1>
            {props.button && (
              <Button text={props.button} onClick={props.onClick} />
            )}
            {props.link && (
              <Link
                to={props.link.href}
                className="inline-block w-auto px-4 py-1 mt-2 mb-4 text-xl font-bold uppercase rounded-md cursor-pointer text-main-smoke hover:shadow hover:bg-main-smoke bg-main-blue hover:text-main-gray focus:outline-none"
                key={props.link.key}
              >
                {props.link.name}
              </Link>
            )}
          </div>
        </div>
      </div>
      {props.children}
      <div className="md:hidden">
        <OrangeBanner
          header={props.title}
          button={props.button}
          onClick={props.onClick}
        />
      </div>
    </div>
  )
}

export default LeftOverlay
