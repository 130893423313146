import BlockContent from "@sanity/block-content-to-react"
import React, { ReactElement } from "react"
import Link from "../components/Link"
import Quote from "../components/Qoute"

const BlockRenderer = (props: any) => {
  const { style = "normal" } = props.node

  // Centrer alle headers
  if (/^h\d/.test(style)) {
    return React.createElement(
      style,
      { className: props.headerClassName },
      props.children
    )
  }

  if (style === "blockquote") {
    return <Quote>{props.children}</Quote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const link = (props: any) => <Link url={props.mark.href}>{props.children}</Link>

export const blockSerializer = (
  headerClassName: string = "text-center md:px-0 px-8 text-lg md:text-xl lg:text-3xl"
) => (container?: ReactElement, styleToWrap?: string) => {
  return {
    container: (props: any) => {
      const elements = Array.from(props.children)

      if (styleToWrap && container) {
        const nonWrappedElements = elements.filter(
          (value: any) => value.props.node.style === styleToWrap
        )
        const wrappedElements = elements.filter(
          (value: any) => value.props.node.style !== styleToWrap
        )

        return (
          <>
            {wrappedElements}
            {React.cloneElement(container, [], nonWrappedElements)}
          </>
        )
      } else return <>{props.children}</>
    },
    // use the list and listItem outside the types
    list: (props: any) => <span>{props.children}</span>,
    marks: { link },
    types: {
      block: (props: any) => BlockRenderer({ ...props, headerClassName }),
      // image: (props: any) => (
      //   <img
      //     src={
      //       sanityImageSrc(props.node.asset)
      //         .dpr(window.devicePixelRatio)
      //         .auto("format")
      //         .width(getWidth())
      //         .height(getHeight())
      //         .url() as string
      //     }
      //     height={getHeight()}
      //     width={getWidth()}
      //     alt="image"
      //     className="my-4 bg-gray-200 rounded-md"
      //   />
      // ),
    },
  }
}
