import React, { FC } from "react"

const Quote: FC = (props) => (
  <div className="w-full px-8 py-6 md:px-0">
    <div className="max-w-lg mx-auto text-3xl font-bold leading-tight text-center text-main-blue">
      <em>"{props.children}"</em>
    </div>
  </div>
)

export default Quote
