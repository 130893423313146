import React, { FC, ReactElement } from "react"
import Button from "./button"

type Props = {
  header: string
  body?: ReactElement
  button?: string
  onClick?: () => void
}

const OrangeBanner: FC<Props> = ({ header, body, button, onClick }) => {
  return (
    <div className="pt-6 text-center bg-main-orange">
      <h2>{header}</h2>
      {body && <p className="max-w-lg px-8 mx-auto">{body}</p>}
      {button && <Button text={button} onClick={onClick} />}
      {!button && <div className="pb-6" />}
    </div>
  )
}

export default OrangeBanner
