import React, { FC } from "react"
import { motion } from "framer-motion"

interface LinkProps {
  url: string
  target?: "_blank" | "_parent" | "_self" | "_top"
}

const Link: FC<LinkProps> = ({ url, target = "_blank", children }) => (
  <motion.a
    href={url}
    target={url.startsWith("mailto") ? "" : target}
    className="inline-flex items-center px-1 pt-1 font-bold leading-5 border-b-2 border-indigo-500 cursor-pointer"
    whileHover={{
      scale: 1.05,
    }}
  >
    {children}
  </motion.a>
)

export default Link
